import React from "react";
import HomePage from "../pages/home/home";
const HomeRoute = () => {
    return (
        <div className="route">

            <HomePage />
        </div>
    )
}

export default HomeRoute