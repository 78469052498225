import React from "react";
import ProjectPage from "../pages/project/project";
const ProjectRoute = () => {
    return (
        <div className="route">
            <ProjectPage />
        </div>
    )
}

export default ProjectRoute